import { useRouter } from 'next/router'
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  Experiment,
  Variants_Enum,
  determineVariantByWeight,
  experiments,
} from '@lojinha/experiment'
import { AddressKind, PaymentMethodType } from '@lojinha/palantir'
import {
  Address,
  BagFeedbacks,
  BagProps,
  CheckoutVisitor,
  Coupon,
  DeliveryDate,
  LocalizationModal,
  MacroPaymentMethod,
  SavedCard,
  User,
  VoucherMethod,
} from './types'

type ContextData = {
  subtotalWithDiscount?: number
  isChatLoading?: boolean
  isChatOpen?: boolean
  isContextLoaded?: boolean
  isAuthenticated: boolean
  deliveryKind?: AddressKind
  centerId?: string
  address?: Address
  deliveryDate?: DeliveryDate
  coupon?: Coupon
  card?: SavedCard
  savedCards?: SavedCard[]
  selectedMacroPaymentMethod?: MacroPaymentMethod
  selectedVoucherBrand?: VoucherMethod
  selectedPaymentMethod?: PaymentMethodType
  deliveryTax?: number
  isBagOpen?: boolean
  doormanAllowed?: boolean
  stepsOrder?: CheckoutVisitor
  mundipaggIsOffline?: boolean
  bag?: BagProps
  discount?: number
  isComponentLoading?: boolean
  cartId?: string
  localizationModal?: LocalizationModal
  isLivupper?: boolean
  freeShippingValue?: number
  referralRewardAmount?: number
  allAddresses?: Address[]
  routeAfterLogin?: string
  ageRestrictionModal?: boolean
  callbackAgeRestriction?: () => void
  isFirstBuy?: boolean
  bagFeedback?: BagFeedbacks
  removedItems?: string[]
  user?: User
  isSearchOpen?: boolean
  searchTerm?: string
  userAgent?: string
  isMobileUserAgent?: boolean
  isCheckoutLoading?: boolean
  isFreeShippingLoading?: boolean
  isCheckoutError?: boolean
  isCheckoutReviewCouponValidating?: boolean
  experiment?: Experiment
  shouldApplyEconomicDeliveryWindowExperiment?: boolean
  loginModal?: boolean
  isCartRecommendationsClosed?: boolean
}

type ContextFunctions = {
  calculateIsFreeShipping: () => boolean | null
  clearContext: () => void
  setIsAuthenticated: (isAuthenticated: boolean) => void
  clearAddress: () => void
  setDeliveryKind: (deliveryKind: AddressKind) => void
  setCenterId: (centerId: string) => void
  setIsContextLoaded: (isLoaded: boolean) => void
  setChatLoading: (isChatLoading: boolean) => void
  setChatOpen: (isChatOpen: boolean) => void
  setAddress: (address: Address) => void
  setDeliveryDate: (date?: DeliveryDate) => void
  setCoupon: (coupon?: Coupon) => void
  setCard: (card?: SavedCard) => void
  setSelectedMacroPaymentMethod: (
    selectedMacroPaymentMethod: MacroPaymentMethod
  ) => void
  setSelectedVoucherBrand: (selectedVoucherBrand: VoucherMethod) => void
  setSelectedPaymentMethod: (selectedPaymentMethod: PaymentMethodType) => void
  setDeliveryTax: (deliveryTax: number) => void
  setBagOpen: (isBagOpen: boolean) => void
  setDoormanAllowed: (doormanAllowed: boolean) => void
  setStepsOrder: (stepsOrder: CheckoutVisitor) => void
  setMundipaggIsOffline: (mundipaggIsOffline: boolean) => void
  setSavedCards: (cards: SavedCard[]) => void
  setBag: (bag: BagProps) => void
  setDiscount: (discount: number) => void
  setIsComponentLoading: (isComponentLoading: boolean) => void
  setCartId: (cartId?: string) => void
  setLocalizationModal: (localizationModal?: LocalizationModal) => void
  setIsLivupper: (isLivupper?: boolean) => void
  setIsFirstBuy: (isFirstBuy: boolean) => void
  setFreeShippingValue: (freeShippingValue?: number) => void
  setReferralRewardAmount: (referralRewardAmount?: number) => void
  setAllAddresses: (allAddresses: Address[]) => void
  setRouteAfterLogin: (route: string) => void
  setAgeRestrictionModal: (ageRestrictionModal?: boolean) => void
  setCallbackAgeRestriction: (callbackAgeRestriction: () => void) => void
  setBagFeedback: (bagFeedback?: BagFeedbacks) => void
  setRemovedItems: (removedItems?: string[]) => void
  setUser: (
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    city?: string | null,
    country?: string | null,
    state?: string | null,
    zipCode?: string | null
  ) => void
  setUserAgent: (userAgent: string) => void
  setIsMobileUserAgent: (isMobileUserAgent: boolean) => void
  setCheckoutLoading: (isCheckoutLoading: boolean) => void
  setLoadingFreeShippingValue: (isFreeShippingLoading: boolean) => void
  setCheckoutError: (isCheckoutError: boolean) => void
  setIsCheckoutReviewCouponValidating: (isCouponValidating: boolean) => void
  setLoginModal: (loginModal?: boolean) => void
  experimentGroup: string
  toggleCartRecommendations: () => void
}

export type ContextValue = ContextData & ContextFunctions

export const Context = createContext<ContextValue>({} as any)

export const ContextProvider: FC<ContextData> = props => {
  const [data, setData] = useState<ContextData>({
    ...props,
  })

  const router = useRouter()
  const pathname = router?.pathname

  const calculateSubtotalWithDiscount = () => {
    const _subtotal = data.bag?.subtotal ?? 0
    const _discount = data.discount ?? 0

    return Math.max(_subtotal - _discount, 0)
  }

  useEffect(() => {
    setData(prev => ({ ...prev, isBagOpen: false }))
  }, [pathname])

  useEffect(() => {
    setData(prev => ({
      ...prev,
      subtotalWithDiscount: calculateSubtotalWithDiscount(),
    }))
  }, [data.bag, data.discount])

  // COMEÇO DO EXPERIMENTO
  const calculateIsFreeShipping = useCallback(() => {
    if (
      data.bag?.subtotal === undefined ||
      data.freeShippingValue === undefined
    ) {
      return null
    }

    if (!data.bag?.subtotal || !data.freeShippingValue) {
      return false
    }

    return data.bag.subtotal >= data.freeShippingValue
  }, [data.bag?.subtotal, data.freeShippingValue])

  useEffect(() => {
    const economicDeliveryWindowExperiment = experiments.find(
      e => e.id === 'economic_delivery_window'
    )

    if (
      !economicDeliveryWindowExperiment ||
      !props.experiment ||
      router.basePath !== '/checkout' ||
      /^\/(pix|confirmation)\/?/.exec(router.pathname)
    ) {
      return
    }

    const isFreeShipping = calculateIsFreeShipping()

    if (isFreeShipping === null) {
      return
    }

    const economicWindowExperimentVariant = props.experiment.getVariant(
      economicDeliveryWindowExperiment.id
    )
    const isEconomicWindowExperimentSetted =
      economicWindowExperimentVariant !== null

    if (isFreeShipping || isEconomicWindowExperimentSetted) {
      return
    }

    const variantValue = determineVariantByWeight(
      economicDeliveryWindowExperiment.variants
    )

    if (!variantValue) {
      return
    }

    props.experiment.setVariant(
      economicDeliveryWindowExperiment.id,
      variantValue
    )
  }, [
    data.bag,
    props.experiment,
    data.freeShippingValue,
    router.basePath,
    router.pathname,
    calculateIsFreeShipping,
  ])

  useEffect(() => {
    const setFirstLoginModal = () => {
      if (props.isAuthenticated) {
        return setData(prev => ({
          ...prev,
          loginModal: false,
        }))
      }

      const alreadyShowedLoginModal = document.cookie.includes(
        '_login_modal=true'
      )

      if (alreadyShowedLoginModal) {
        return setData(prev => ({
          ...prev,
          loginModal: false,
        }))
      }

      setData(prev => ({
        ...prev,
        loginModal: true,
      }))
    }

    setTimeout(setFirstLoginModal, 2000)
  }, [props.isAuthenticated])

  const shouldApplyEconomicDeliveryWindowExperiment = useMemo(() => {
    const economicDeliveryWindowExperiment = experiments.find(
      e => e.id === 'economic_delivery_window'
    )

    if (!economicDeliveryWindowExperiment) {
      return false
    }

    const variant = props.experiment?.getVariant(
      economicDeliveryWindowExperiment.id
    )

    const isFreeShipping = calculateIsFreeShipping()

    return variant === Variants_Enum.B && !isFreeShipping
  }, [props.experiment, calculateIsFreeShipping])
  // FIM DO EXPERIMENTO

  const clearContext = () => {
    setData({
      isChatLoading: false,
      isChatOpen: false,
      isContextLoaded: false,
      isAuthenticated: false,
      deliveryKind: undefined,
      centerId: undefined,
      address: undefined,
      deliveryDate: undefined,
      coupon: undefined,
      card: undefined,
      savedCards: undefined,
      selectedMacroPaymentMethod: undefined,
      selectedVoucherBrand: undefined,
      selectedPaymentMethod: undefined,
      deliveryTax: undefined,
      isBagOpen: false,
      doormanAllowed: false,
      stepsOrder: undefined,
      mundipaggIsOffline: false,
      bag: undefined,
      discount: undefined,
      isComponentLoading: false,
      cartId: undefined,
      localizationModal: undefined,
      isLivupper: false,
      referralRewardAmount: undefined,
      ageRestrictionModal: false,
      callbackAgeRestriction: undefined,
      isFirstBuy: true,
      bagFeedback: undefined,
      removedItems: undefined,
      user: undefined,
      isCheckoutLoading: false,
      isFreeShippingLoading: false,
      isCheckoutError: false,
      isCheckoutReviewCouponValidating: false,
      experiment: props.experiment,
      shouldApplyEconomicDeliveryWindowExperiment: false,
      loginModal: false,
      isCartRecommendationsClosed: false,
    })
  }

  const setBagOpen = (isBagOpen: boolean) =>
    setData(prev => ({ ...prev, isBagOpen }))

  const setDeliveryKind = (deliveryKind: AddressKind) =>
    setData(prev => ({ ...prev, deliveryKind }))

  const clearAddress = () =>
    setData(prev => ({ ...prev, address: undefined, deliveryTax: undefined }))

  const setCenterId = (centerId: string) =>
    setData(prev => ({ ...prev, centerId }))

  const setAddress = (address: Address) =>
    setData(prev => ({ ...prev, address }))

  const setDeliveryDate = (deliveryDate?: DeliveryDate) =>
    setData(prev => ({ ...prev, deliveryDate }))

  const setCoupon = (coupon?: Coupon) => setData(prev => ({ ...prev, coupon }))

  const setSelectedMacroPaymentMethod = (
    selectedMacroPaymentMethod: MacroPaymentMethod
  ) => setData(prev => ({ ...prev, selectedMacroPaymentMethod }))

  const setSelectedVoucherBrand = (selectedVoucherBrand: VoucherMethod) =>
    setData(prev => ({ ...prev, selectedVoucherBrand }))

  const setCard = (card?: SavedCard) => setData(prev => ({ ...prev, card }))

  const setSelectedPaymentMethod = (selectedPaymentMethod: PaymentMethodType) =>
    setData(prev => ({ ...prev, selectedPaymentMethod }))

  const setDeliveryTax = (deliveryTax: number) =>
    setData(prev => ({ ...prev, deliveryTax }))

  const setDoormanAllowed = (doormanAllowed: boolean) =>
    setData(prev => ({ ...prev, doormanAllowed }))

  const setIsAuthenticated = (isAuthenticated: boolean) =>
    setData(prev => ({ ...prev, isAuthenticated }))

  const setStepsOrder = (stepsOrder: CheckoutVisitor) =>
    setData(prev => ({ ...prev, stepsOrder }))

  const setMundipaggIsOffline = (mundipaggIsOffline: boolean) =>
    setData(prev => ({ ...prev, mundipaggIsOffline }))

  const setSavedCards = (savedCards: SavedCard[]) =>
    setData(prev => ({ ...prev, savedCards }))

  const setBag = (bag: BagProps) => setData(prev => ({ ...prev, bag }))

  const setDiscount = (discount: number) =>
    setData(prev => ({ ...prev, discount }))

  const setIsComponentLoading = (isComponentLoading: boolean) =>
    setData(prev => ({ ...prev, isComponentLoading }))

  const setCartId = (cartId?: string) => setData(prev => ({ ...prev, cartId }))

  const setLocalizationModal = (localizationModal?: LocalizationModal) =>
    setData(prev => ({ ...prev, localizationModal }))

  const setIsContextLoaded = (isContextLoaded: boolean) =>
    setData(prev => ({ ...prev, isContextLoaded }))

  const setChatLoading = (isChatLoading: boolean) =>
    setData(prev => ({ ...prev, isChatLoading }))

  const setChatOpen = (isChatOpen: boolean) =>
    setData(prev => ({ ...prev, isChatOpen }))

  const setIsLivupper = (isLivupper?: boolean) =>
    setData(prev => ({ ...prev, isLivupper }))

  const setIsFirstBuy = (isFirstBuy: boolean) =>
    setData(prev => ({ ...prev, isFirstBuy }))

  const setFreeShippingValue = (freeShippingValue?: number) =>
    setData(prev => ({ ...prev, freeShippingValue }))

  const setReferralRewardAmount = (referralRewardAmount?: number) =>
    setData(prev => ({ ...prev, referralRewardAmount }))

  const setAllAddresses = (allAddresses: Address[]) =>
    setData(prev => ({ ...prev, allAddresses }))

  const setRouteAfterLogin = (routeAfterLogin: string) =>
    setData(prev => ({ ...prev, routeAfterLogin }))

  const setAgeRestrictionModal = (ageRestrictionModal?: boolean) =>
    setData(prev => ({ ...prev, ageRestrictionModal }))

  const setCallbackAgeRestriction = (callbackAgeRestriction: () => void) =>
    setData(prev => ({ ...prev, callbackAgeRestriction }))

  const setBagFeedback = (bagFeedback?: BagFeedbacks) =>
    setData(prev => ({ ...prev, bagFeedback }))

  const setRemovedItems = (removedItems?: string[]) =>
    setData(prev => ({ ...prev, removedItems }))

  const setLoginModal = (loginModal?: boolean) =>
    setData(prev => ({ ...prev, loginModal }))

  const setUser = (
    id: string,
    name: string,
    email: string,
    phoneNumber?: string | null,
    city?: string | null,
    country?: string | null,
    state?: string | null,
    zipCode?: string | null
  ) =>
    setData(prev => ({
      ...prev,
      user: {
        id,
        name,
        email,
        phoneNumber,
        city,
        country,
        state,
        zipCode,
      },
    }))

  const setUserAgent = (userAgent: string) =>
    setData(prev => ({ ...prev, userAgent }))

  const setIsMobileUserAgent = (isMobileUserAgent: boolean) =>
    setData(prev => ({ ...prev, isMobileUserAgent }))

  const setCheckoutLoading = (isCheckoutLoading: boolean) =>
    setData(prev => ({ ...prev, isCheckoutLoading }))

  const setLoadingFreeShippingValue = (isFreeShippingLoading: boolean) =>
    setData(prev => ({ ...prev, isFreeShippingLoading }))

  const setCheckoutError = (isCheckoutError: boolean) =>
    setData(prev => ({ ...prev, isCheckoutError }))

  const setIsCheckoutReviewCouponValidating = (
    isCheckoutReviewCouponValidating: boolean
  ) => setData(prev => ({ ...prev, isCheckoutReviewCouponValidating }))

  const toggleCartRecommendations = () => {
    setData(prev => ({
      ...prev,
      isCartRecommendationsClosed: !prev.isCartRecommendationsClosed,
    }))
  }

  return (
    <Context.Provider
      value={{
        ...data,
        get experimentGroup() {
          return props.experiment?.experimentGroup || ''
        },
        shouldApplyEconomicDeliveryWindowExperiment,
        calculateIsFreeShipping,
        clearContext,
        clearAddress,
        setChatLoading,
        setChatOpen,
        setDeliveryKind,
        setCenterId,
        setIsContextLoaded,
        setAddress,
        setDeliveryDate,
        setCoupon,
        setSelectedMacroPaymentMethod,
        setSelectedVoucherBrand,
        setCard,
        setSelectedPaymentMethod,
        setDeliveryTax,
        setBagOpen,
        setDoormanAllowed,
        setIsAuthenticated,
        setStepsOrder,
        setMundipaggIsOffline,
        setSavedCards,
        setBag,
        setDiscount,
        setIsComponentLoading,
        setCartId,
        setLocalizationModal,
        setIsLivupper,
        setIsFirstBuy,
        setFreeShippingValue,
        setReferralRewardAmount,
        setAllAddresses,
        setRouteAfterLogin,
        setAgeRestrictionModal,
        setCallbackAgeRestriction,
        setBagFeedback,
        setRemovedItems,
        setUser,
        setUserAgent,
        setIsMobileUserAgent,
        setCheckoutLoading,
        setLoadingFreeShippingValue,
        setCheckoutError,
        setIsCheckoutReviewCouponValidating,
        setLoginModal,
        toggleCartRecommendations,
      }}
      {...props}
    />
  )
}

export const useLojinhaContext = (): ContextValue => {
  return useContext(Context)
}
