import { useState } from 'react'

import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from '@lojinha/helpers'

import { experiments } from '../experiments'
import { determineVariantByWeight, stringToValidEnumValue } from '../logic'
import { Experiment, Variants_Enum } from '../types'

function getExperimentLocalStorageKey(experimentId: string) {
  return `experiment.${experimentId}`.toLowerCase()
}

function getLocalStorageVariant(experimentId: string) {
  const key = getExperimentLocalStorageKey(experimentId)
  return getLocalStorage(key)
}

function setLocalStorageVariant(
  experimentId: string,
  variant: Variants_Enum | null
) {
  const key = getExperimentLocalStorageKey(experimentId)
  if (!variant) {
    return removeLocalStorage(key)
  }
  return setLocalStorage(key, variant)
}

function isExperimentIdValid(experimentId: string) {
  return experiments.some(e => e.id === experimentId)
}

export const useExperiment = (
  userExperimentVariants: Record<string, string>
): Experiment => {
  function getVariant(experimentId: string): Variants_Enum | null | undefined {
    if (!isExperimentIdValid(experimentId)) {
      return undefined
    }
    const initialValue = userExperimentVariants[experimentId]?.toUpperCase()
    const customValue = getLocalStorageVariant(experimentId)
    const value = customValue ?? initialValue
    return stringToValidEnumValue(value)
  }

  function getEconomicDeliveryWindowVariant(
    variantValue: Variants_Enum,
    selectedDeliveryPeriod: string | null
  ): Variants_Enum {
    if (
      variantValue === Variants_Enum.B &&
      selectedDeliveryPeriod === 'FREE_SHIPPING'
    ) {
      return Variants_Enum.B
    }

    return Variants_Enum.A
  }

  function getUserExperiments(): Record<string, Variants_Enum> {
    return experiments.reduce<Record<string, Variants_Enum>>((acc, cur) => {
      const variantValue = getVariant(cur.id)

      if (cur.id === 'economic_delivery_window' && variantValue) {
        acc[`_experiment.${cur.id}`] = getEconomicDeliveryWindowVariant(
          variantValue,
          getLocalStorage('selectedDeliveryPeriod')
        )
      } else if (variantValue) {
        acc[`_experiment.${cur.id}`] = variantValue
      }

      return acc
    }, {})
  }

  function getExperimentGroup(): string {
    return experiments
      .map(({ id }) => {
        const value = getVariant(id)
        return value ? `${id.charAt(0).toUpperCase()}var${value}` : null
      })
      .filter(Boolean)
      .join('-')
  }

  const [experimentGroup, setExperimentGroup] = useState<string>(
    getExperimentGroup()
  )

  function refreshExperimentGroup(): void {
    setExperimentGroup(getExperimentGroup())
  }

  function setVariant(
    experimentId: string,
    variant: Variants_Enum | null
  ): void {
    if (!isExperimentIdValid(experimentId)) {
      return
    }

    const value = Variants_Enum[variant as keyof typeof Variants_Enum] || null
    setLocalStorageVariant(experimentId, value)
    refreshExperimentGroup()
  }

  function drawVariantIfUnset(experimentId: string): Variants_Enum | undefined {
    const currentVariant = getVariant(experimentId)
    if (currentVariant !== null) {
      return currentVariant
    }

    const variants = experiments.find(e => e.id === experimentId)?.variants
    if (!variants) {
      return undefined
    }

    const drawnVariant = determineVariantByWeight(variants)
    if (!drawnVariant) {
      return undefined
    }

    setVariant(experimentId, drawnVariant)
    return drawnVariant
  }

  function reset(): void {
    experiments.forEach(experiment =>
      setLocalStorageVariant(experiment.id, null)
    )
    refreshExperimentGroup()
  }

  return {
    experimentGroup,
    getUserExperiments,
    getVariant,
    setVariant,
    drawVariantIfUnset,
    reset,
  }
}
